import { Suspense } from 'react';
// import { useLocation } from 'react-router-dom';
import { Layout } from '@pledge-earth/web-components';
import { Outlet } from 'react-router-dom';

import { LoadingGeneric } from '../../components/Loading/LoadingGeneric';
import { Menu as MenuLeft } from '../../components/MenuLeft/Menu';
import { TestModeBar } from '../../components/TestModeBar/TestModeBar';
import { TopBar } from '../../components/TopBar/TopBar';
import { useIsTestMode } from '../../hooks/useIsTestMode';
import { CommandPalette } from '../../components/CommandPalette/CommandPalette';
import { FeatureFlag } from '../../components/FeatureFlag/FeatureFlag';

import './MainLayout.scss';

const { Header, Content } = Layout;

export function MainLayout(props: { testModeSensitive: boolean }) {
  const testModeEnabled = useIsTestMode();

  // Show Test Mode Bar
  const showTestModeBar = testModeEnabled && props.testModeSensitive;

  return (
    <>
      <FeatureFlag name="command-k">
        <CommandPalette />
      </FeatureFlag>
      <Layout className="MainLayout bg-background-default">
        <MenuLeft />
        <Layout className="MainLayout__right">
          <Header className="MainLayout__header desktop:!w-full">
            <TopBar />
          </Header>
          {showTestModeBar ? <TestModeBar /> : null}
          <Content className="overflow-x-hidden bg-background-subdued px-4 py-6 desktop:mt-0 desktop:p-8">
            <div className="mx-auto flex min-h-full min-w-0 max-w-[1200px] flex-wrap">
              <div className="relative min-h-px max-w-full shrink-0 grow-0 basis-full">
                <Suspense fallback={<LoadingGeneric delayMs={300} />}>
                  <Outlet />
                </Suspense>
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}
