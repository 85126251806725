// eslint-disable-next-line no-restricted-imports -- Drawer will be replaced at the same time as layout
import { Drawer } from '@pledge-earth/web-components';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { BREAKPOINTS, useMediaQuery } from '../../hooks/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { toggledIsMobileMenuOpen } from '../../store/settings/reducers';

import { MenuLeft } from './MenuLeft';

export function Menu() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isMobileMenuOpen = useAppSelector((state) => state.settings.isMobileMenuOpen);
  const isMobileView = useMediaQuery(BREAKPOINTS.IS_MOBILE_VIEW);

  const toggleMobileMenu = () => {
    dispatch(toggledIsMobileMenuOpen({}));
  };

  // close the mobile menu whenever a link is clicked
  useEffect(() => {
    if (isMobileView && location.pathname) {
      dispatch(toggledIsMobileMenuOpen({ isMobileMenuOpen: false }));
    }
  }, [dispatch, isMobileView, location.pathname]);

  if (isMobileView) {
    return (
      <Drawer
        closable={false}
        open={isMobileMenuOpen}
        placement="left"
        onClose={toggleMobileMenu}
        maskClosable={true}
        getContainer={undefined}
        width={248}
      >
        <MenuLeft />
      </Drawer>
    );
  }

  return <MenuLeft />;
}
