import {
  ArrowRightIcon,
  Avatar,
  Dialog,
  DialogBody,
  DialogHeader,
  DialogModal,
  DialogTitle,
  FlaskIcon,
  Input,
  OptionListHeader,
  OptionListItem,
  OptionListSection,
  OverlayCloseButton,
  Size,
  SparkleIcon,
  Text,
} from '@pledge-earth/product-language';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Collection, ComboBox, ListBox } from 'react-aria-components';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { themeChanged } from '../../store/settings/reducers';
import { useIsTestMode } from '../../hooks/useIsTestMode';
import { useNavigateWithTestMode } from '../../hooks/useNavigateWithTestMode';

interface Command {
  label: string;
  id: string;
  options: {
    id: string;
    name: string;
    route?: string;
    action?: () => void;
    icon?: React.ReactNode;
  }[];
}

export function CommandPalette() {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useAppSelector((state) => state.settings.theme);
  const isTestMode = useIsTestMode();
  const { pathname } = useLocation();
  const navigateWithTestMode = useNavigateWithTestMode();

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'k' && e.metaKey) {
        setOpen(!isOpen);
      }
    };
    document.addEventListener('keydown', handleKeyDown, true);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [isOpen]);

  const switchMode = useCallback(() => {
    navigateWithTestMode(pathname, { testMode: !isTestMode });
  }, [isTestMode, navigateWithTestMode, pathname]);

  const options: Command[] = [
    {
      label: 'Actions',
      id: 'actions',
      options: [
        {
          id: 'actions/theme',
          name: 'Toggle theme',
          action: () => dispatch(themeChanged({ theme: theme === 'light' ? 'dark' : 'light' })),
          icon: <SparkleIcon className="p-0.5 text-default-subdued" />,
        },
        {
          id: 'actions/test_mode',
          name: 'Toggle test mode',
          action: switchMode,
          icon: <FlaskIcon className="p-0.5 text-default-subdued" />,
        },
      ],
    },
    {
      label: 'Navigate',
      id: 'navigate',
      options: [
        {
          id: 'navigate/emissions',
          name: 'Emissions',
          route: '/emissions',
        },
        {
          id: 'navigate/calculator',
          name: 'Calculator',
          route: '/calculators/freight',
        },
        {
          id: 'navigate/reports',
          name: 'Reports',
          route: '/reports/emissions',
        },
        {
          id: 'navigate/client_directory',
          name: 'Client directory',
          route: '/directory/client',
        },
        {
          id: 'navigate/supplier_directory',
          name: 'Supplier directory',
          route: '/directory/supplier',
        },
      ],
    },
  ];

  return (
    <DialogModal isOpen={isOpen} onOpenChange={setOpen}>
      <Dialog>
        <DialogHeader>
          <DialogTitle>Quick search</DialogTitle>
          <OverlayCloseButton label="Close" />
        </DialogHeader>
        <DialogBody>
          <ComboBox
            defaultItems={options}
            menuTrigger="focus"
            autoFocus={true}
            shouldFocusWrap={true}
            allowsEmptyCollection={true}
            aria-label="Search for a command"
            onSelectionChange={(item) => {
              if (!item) return;
              const [parent] = (item as string).split('/');
              const option = options.find((route) => route.id === parent)?.options.find((option) => option.id === item);
              if (option?.route) {
                navigate(option?.route);
                setOpen(false);
              }
              if (option?.action) {
                option.action();
                setOpen(false);
              }
            }}
          >
            <Input autoFocus={true} placeholder="Search..." size={Size.Loose} className="w-full" />
            <ListBox items={options} className="mt-4" autoFocus={true}>
              {(item) => (
                <OptionListSection id={item.id}>
                  <OptionListHeader className="px-2">{item.label}</OptionListHeader>
                  <Collection items={item.options}>
                    {(option) => (
                      <OptionListItem
                        className="flex items-center gap-2 rounded-md p-2"
                        id={option.id}
                        textValue={option.name}
                      >
                        {option.route ? (
                          <>
                            <Avatar variant="square" size="24">
                              <ArrowRightIcon className="p-0.5 text-default-subdued" />
                            </Avatar>
                            <Text slot="description">{option.name}</Text>
                          </>
                        ) : (
                          <>
                            <Avatar variant="square" size="24">
                              {option.icon ? option.icon : <SparkleIcon className="p-0.5 text-default-subdued" />}
                            </Avatar>
                            <Text slot="description">{option.name}</Text>
                          </>
                        )}
                      </OptionListItem>
                    )}
                  </Collection>
                </OptionListSection>
              )}
            </ListBox>
          </ComboBox>
        </DialogBody>
      </Dialog>
    </DialogModal>
  );
}
