import { IconButton, MenuExpandIcon, twMerge } from '@pledge-earth/product-language';
import { useIntl } from 'react-intl';

import { BREAKPOINTS, useMediaQuery } from '../../hooks/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { toggledIsMenuCollapsed, toggledIsMobileMenuOpen } from '../../store/settings/reducers';
import { TrialCountDown } from '../TrialCountDown/TrialCountDown';

import { UserMenu } from './UserMenu/UserMenu';

export function TopBar() {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const isMenuCollapsed = useAppSelector((state) => state.settings.isMenuCollapsed);
  const isMobileView = useMediaQuery(BREAKPOINTS.IS_MOBILE_VIEW);

  const toggleMenu = () => {
    if (isMobileView) {
      dispatch(toggledIsMobileMenuOpen({ isMobileMenuOpen: true }));
    } else {
      dispatch(toggledIsMenuCollapsed({ isMenuCollapsed: false }));
    }
  };

  return (
    <div className="flex h-16 min-h-16 items-center justify-end border-b border-default bg-background-subdued px-4 desktop:px-6">
      <IconButton
        variant="subtle"
        label={formatMessage({ id: 'expand' })}
        className={twMerge('mr-auto shrink-0', !isMenuCollapsed && !isMobileView ? 'hidden' : '')}
        onPress={toggleMenu}
      >
        <MenuExpandIcon />
      </IconButton>

      <TrialCountDown />
      <UserMenu />
    </div>
  );
}
