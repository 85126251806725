import store from 'store';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface SettingsState {
  theme: 'light' | 'dark';
  locale: string;
  isMobileMenuOpen: boolean;
  isMenuCollapsed: boolean;
}

const { actions, reducer } = createSlice({
  name: 'settings',
  initialState: (): SettingsState => ({
    theme: store.get(`app.settings.theme`) === 'dark' ? 'dark' : 'light',
    locale: 'en-GB',
    isMobileMenuOpen: false,
    isMenuCollapsed: false,
  }),
  reducers: {
    toggledIsMenuCollapsed(state, action: PayloadAction<{ isMenuCollapsed?: SettingsState['isMenuCollapsed'] }>) {
      return {
        ...state,
        isMenuCollapsed: action.payload.isMenuCollapsed ?? !state.isMenuCollapsed,
        isMobileMenuOpen: false,
      };
    },
    toggledIsMobileMenuOpen(state, action: PayloadAction<{ isMobileMenuOpen?: SettingsState['isMobileMenuOpen'] }>) {
      return {
        ...state,
        isMenuCollapsed: false,
        isMobileMenuOpen: action.payload.isMobileMenuOpen ?? !state.isMobileMenuOpen,
      };
    },
    themeChanged(state, action: PayloadAction<{ theme: SettingsState['theme'] }>) {
      return { ...state, theme: action.payload.theme };
    },
    localeChanged(state, action: PayloadAction<{ language: string | null | undefined }>) {
      if (!action.payload.language) {
        return state;
      }

      return { ...state, locale: action.payload.language };
    },
  },
});

export const { toggledIsMenuCollapsed, toggledIsMobileMenuOpen, themeChanged, localeChanged } = actions;

export { reducer };
